import React from 'react';
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UZ_flag from '../../assets/img/uz.jpg';
import RU_flag from '../../assets/img/ru.png';
import { MdLanguage } from 'react-icons/md';
import { useSelector } from 'react-redux';
import NavbarStyle from './NavbarStyle';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { get } from 'lodash';
// address_route
import routesSklad from 'routes';
import routesShop from 'routesShop';

export default function HeaderLinks(props) {
  const { getMe, jobTitle } = useSelector((state) => state.main);
  console.log('jobTitle', jobTitle);

  let routes = [];

  if (jobTitle === 'warehouse_manager') {
    routes = routesSklad;
  } else if (jobTitle === 'shopkeeper' || jobTitle === 'Shopkeeper') {
    routes = routesShop;
  } else if (jobTitle === 'Showroom' || jobTitle === 'showroom') {
    routes = routesShop;
  } else {
    routes = [];
  }

  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  const menuBg = useColorModeValue('white', 'navy.800');
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const changeLn = (v) => {
    AsyncStorage.setItem('lan', v);
    i18next.changeLanguage(v);
  };

  const logout = () => {
    navigate('/');
  };

  const userName = get(getMe, 'firstName', '');

  return (
    <NavbarStyle>
      <Flex
        w={{ sm: '100%', md: 'auto' }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        <SidebarResponsive routes={routes} />
        <Menu>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: '30px', md: 'unset' }}
            minW={{ base: 'unset', md: '400px', xl: '450px' }}
            maxW={{ base: '360px', md: 'unset' }}
          ></MenuList>
        </Menu>
        <Menu>
          <MenuButton ps="10px" pe="10px" pb={'6px'}>
            <Icon
              mt="6px"
              as={MdLanguage}
              color={navbarIcon}
              w="18px"
              h="18px"
              me="10px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: '30px', md: 'unset' }}
            minW={{ base: 'unset', md: '200px', xl: '200px' }}
            maxW={{ base: '360px', md: 'unset' }}
          >
            <Flex flexDirection="column">
              <MenuItem className="imgCard" onClick={() => changeLn('uz')}>
                <img src={UZ_flag} alt="Flag" className="flag_img" />
                <Text
                  color={i18next.language === 'uz' ? 'brand.200' : 'brand.10'}
                >
                  O'zbekcha
                </Text>
              </MenuItem>
              <MenuItem
                className="imgCard"
                onClick={() => changeLn('ru')}
                style={{ marginTop: 10 }}
              >
                <img src={RU_flag} alt="Flag" className="flag_img" />
                <Text
                  color={i18next.language === 'ru' ? 'brand.200' : 'brand.10'}
                >
                  Русский
                </Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>

        <Button
          variant="no-hover"
          bg="transparent"
          ps={'0px'}
          pe={'10px'}
          minW="unset"
          minH="unset"
          h="18px"
          w="max-content"
          onClick={toggleColorMode}
        >
          <Icon
            me="10px"
            h="18px"
            w="18px"
            color={navbarIcon}
            as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
          />
        </Button>

        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              name="Adela Parkson"
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; {t('Hi')}, {userName}
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              {/* <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">{t('Profile Settings')}</Text>
              </MenuItem> */}

              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={logout}
              >
                <Text fontSize="sm">{t('Log out')}</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </NavbarStyle>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
