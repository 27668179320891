import React from 'react';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const SearchInput = ({
  variant,
  background,
  children,
  placeholder,
  borderRadius,
  isLoading = false,
  onGetText = () => {},
  onConfirm = () => {},
  isIconHas = true,
  type = 'search',
  ...rest
}) => {
  const { t } = useTranslation();

  const searchIconColor = useColorModeValue('brand.300', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'brand.200');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  return (
    <InputGroup w={{ base: '100%', md: '200px' }} {...rest}>
      {isIconHas ? (
        <InputLeftElement
          children={
            <IconButton
              isLoading={isLoading}
              bg="inherit"
              borderRadius={'8px'}
              _hover="none"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
              icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
              onClick={onConfirm}
            ></IconButton>
          }
        />
      ) : null}

      <Input
        type={type}
        variant="search"
        fontSize="sm"
        bg={background ? background : inputBg}
        color={inputText}
        borderRadius={'8px'}
        fontWeight="500"
        _placeholder={{ color: 'gray.400', fontSize: '14px' }}
        placeholder={placeholder ? placeholder : `${t('Qidirish')}`}
        onChange={(v) => onGetText(v.target.value)}
      />
    </InputGroup>
  );
};

export default SearchInput;
