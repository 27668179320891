import React, { useEffect, useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import AddIncomingPayment from 'components/Modals/AddIncomingPayment';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';
import api from 'api';

export default function ViewSSHOP() {
  const { t } = useTranslation();

  const { token, getMe } = useSelector((state) => state.main);
  const uAccount = get(getMe, 'uAccount', '');
  const uDollarAccount = get(getMe, 'uDollarAccount', '');

  const errorRef = useRef();
  const successRef = useRef();
  const paymentRef = useRef();

  const navigation = useNavigate();

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const btnTextColor = useColorModeValue('black', 'white');
  const pdfBtn = useColorModeValue('yellow.600', 'yellow.800');

  const location = useLocation();
  const data = get(location, 'state', {});
  const documentLines = get(data, 'documentLines', []);
  const docEntry = get(data, 'docEntry', '');

  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(documentLines);
  const docTotal = mainData?.reduce(
    (acc, line) => acc + line.price * line.quantity,
    0,
  );
  const cancelRequest = () => {
    setIsLoading(true);

    const documentLines = mainData.map((v, i) => {
      return {
        itemCode: get(v, 'itemCode', ''),
        itemDescription: get(v, 'itemDescription', ''),
        quantity: Number(get(v, 'remainingOpenInventoryQuantity', '')),
        baseLine: get(v, 'lineNum', ''),
        baseEntry: get(data, 'docEntry', ''),
        baseType: 13,
      };
    });

    let allData = {
      cardCode: get(data, 'cardCode', ''),
      cardName: get(data, 'cardName', ''),
      documentLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`credit-notes/invoice-to-credit-note`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  console.log(data);

  const payToItem = (date, user, acc, summa, currency, rate) => {
    setIsLoading(true);

    let allData = {
      cardCode: get(data, 'cardCode', ''),
      docCurrency: currency,
      docType: 'rCustomer',
      cashAccount: acc,
      cashSum: Number(summa),
      docRate: rate,

      invoiceCurrency: get(data, 'docCurrency', ''),
      paymentInvoices: [
        {
          lineNum: 0,
          docEntry: get(data, 'docEntry', ''),
          sumApplied: Number(summa),
          invoiceType: 'it_Invoice',
        },
      ],
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`incoming-payments/invoice-to-incoming`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const downloadPDF = () => {
    setIsLoading(true);
    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    };
    api
      .get(`pdf-generators/invoice/${docEntry}`, header)
      .then((response) => {
        setIsLoading(false);
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `invoice_Pro_Tools_${docEntry}.pdf`; // Specify the file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link);
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const changeQuant = (i, quant) => {
    let changedData = [...mainData];
    changedData[i].remainingOpenInventoryQuantity = quant;
    changedData[i].isEdited = true;
    setMainData(changedData);
  };

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Input
              value={`№ ${get(data, 'docNum', '')}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('Ism')} : ${get(data, 'cardName', '')}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('Sana')} : ${time.to(
                get(data, 'docDate', ''),
                'DD-MM-YYYY',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('Sum')} : ${get(data, 'docTotal', '')} ${get(
                data,
                'docCurrency',
                '',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('debt')} : ${
                get(data, 'docTotal', '') - get(data, 'paidToDate', '')
              } ${get(data, 'docCurrency', '')}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('paidToDate')} : ${get(data, 'paidToDate', '')} ${get(
                data,
                'docCurrency',
                '',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('totalQuantity')}
                  </Th>
                  {/* <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('openQuantity')}
                  </Th> */}
                  {/* <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('removedQuantity')}
                  </Th> */}

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Narxi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('total_sum')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'itemDescription', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Text color={inputText}>{get(v, 'quantity', '')}</Text>
                      </Td>
                      {/* <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'remainingOpenInventoryQuantity', '')}
                          onChange={(title) =>
                            changeQuant(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td> */}
                      {/* <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Text color={inputText}>
                          {get(v, 'quantity', '') -
                            get(v, 'remainingOpenInventoryQuantity', '')}
                        </Text>
                      </Td> */}

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'unitPrice', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {(v.quantity * v.unitPrice).toFixed(2)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

          <Box justifyContent={'end'} display={'flex'} mt={'20px'}>
            <Button
              me={'10px'}
              isLoading={isLoading}
              onClick={downloadPDF}
              width={200}
              backgroundColor={pdfBtn}
            >
              {'PDF'}
            </Button>
            <Button
              me={'10px'}
              isLoading={isLoading}
              onClick={() =>
                paymentRef.current?.open({
                  docTotal:
                    get(data, 'docTotal', '') - get(data, 'paidToDate', ''),
                  currency: get(data, 'docCurrency', ''),
                })
              }
              width={200}
            >
              {t('Оплата')}
            </Button>
            <Button
              isLoading={isLoading}
              onClick={cancelRequest}
              width={200}
              backgroundColor={'red'}
            >
              {t('Возврат')}
            </Button>
          </Box>
        </Card>
      </Box>
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
      {/* <AddIncomingPayment
        getRef={(r) => (paymentRef.current = r)}
        onConfirm={(valuta, summa, rate) => payToItem(valuta, summa, rate)}
      /> */}
      <AddIncomingPayment
        getRef={(r) => (paymentRef.current = r)}
        isTypesShow={true}
        isDocTotalaShow={true}
        isShowDate={false}
        onConfirm={(date, user, acc, summa, currency, rate) =>
          payToItem(date, user, acc, summa, currency, rate)
        }
      />
    </>
  );
}
