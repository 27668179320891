import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
  Flex,
  useColorMode,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import SuccessModal from 'components/Modals/SuccessModal';
import ClientsModal from 'components/Modals/ClientsModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';
import ProductsInWarehouseModal from '../../../components/Modals/ProductsInWarehouseModal/index';
import ItemsByWarehouseByPriceListModal from 'components/Modals/ItemsByWarehouseByPriceListModal.tsx';

export default function CreateSSHOP() {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const { getMe, token } = useSelector((state) => state.main);
  const uWarehouse = get(getMe, 'uWarehouse', '');

  const errorRef = useRef();
  const successRef = useRef();
  const clientsRef = useRef();
  const productsInWhsRef = useRef();

  const navigation = useNavigate();

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const btnTextColor = useColorModeValue('black', 'white');
  const btnDefault = useColorModeValue('gray.200', 'gray.500');
  const textDefault = useColorModeValue('black', 'White');

  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(
    Array(10)
      .fill()
      .map((_, idx) => ({
        lineNum: idx,
        itemCode: '',
        itemName: '',
        quantity: '',
        unitPrice: '',
        warehouseCode: '',
        warehouseName: '',
      })),
  );
  const [valuta, setValuta] = useState('USD');
  const docTotal = mainData?.reduce(
    (acc, line) => acc + line.unitPrice * line.quantity,
    0,
  );
  const [user, setUser] = useState({
    cardCode: '',
    cardName: t('Foydalanuvchini tanlang'),
    priceListNum: '1',
  });

  const typeOfValuta = [
    {
      title: t('Dollar'),
      val: 'USD',
    },
    {
      title: t('Som'),
      val: 'UZS',
    },
  ];
  const [isItemsByWarehouseModalOpen, setIsItemsByWarehouseModalOpen] =
    useState(false);
  const [idx, setIdx] = useState(undefined);

  const createNewRequest = () => {
    setIsLoading(true);

    const documentLines = mainData
      .filter((item) => item.itemCode !== null)
      .map((v) => {
        if (get(v, 'itemCode', '')) {
          return {
            itemCode: get(v, 'itemCode', ''),
            itemDescription: get(v, 'itemName', ''),
            quantity: Number(get(v, 'quantity', '')),
            unitPrice: Number(get(v, 'unitPrice', '')),
            warehouseCode: uWarehouse,
          };
        }
        return null;
      })
      .filter((v) => v !== null);

    let allData = {
      cardCode: get(user, 'cardCode', ''),
      cardName: get(user, 'cardName', ''),
      docCurrency: valuta,
      documentLines,
    };
    console.log('allData:::', allData);
    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`invoices`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const addItem = () => {
    setMainData((pr) => [
      ...pr,
      {
        itemCode: '',
        itemName: '',
        quantity: '',
        unitPrice: '',
        warehouseCode: '',
      },
    ]);
  };

  const handleSelect = (i, val) => {
    const changedData = [...mainData];
    changedData[i] = {
      ...changedData[i],
      itemCode: get(val, 'itemCode', ''),
      itemName: get(val, 'itemName', ''),
      unitPrice: get(val, 'price', ''),
      warehouseCode: uWarehouse,
    };
    setMainData(changedData);
  };

  const changeQuant = (i, quant) => {
    let changedData = [...mainData];
    changedData[i].quantity = quant;
    setMainData(changedData);
  };

  const changePrice = (i, price_item) => {
    let changedData = [...mainData];
    changedData[i].unitPrice = price_item;
    setMainData(changedData);
  };

  const remove = (i) => {
    let changedData = [...mainData];
    changedData.splice(i, 1);
    setMainData(changedData);
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Button onClick={() => clientsRef.current?.open()} minW={'200px'}>
              {get(user, 'cardName', '')}
            </Button>
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              {typeOfValuta.map((item, index) => {
                return (
                  <Button
                    backgroundColor={
                      item.val === valuta ? 'brand.300' : btnDefault
                    }
                    onClick={() => setValuta(item.val)}
                    width={'47%'}
                    color={item.val === valuta ? 'white' : textDefault}
                  >
                    {item.title}
                  </Button>
                );
              })}
            </Box>
            <Input
              value={`${t('Mijoz balansi')} : ${get(
                user,
                'currentAccountBalance',
                '',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    width={'20px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    №
                  </Th>
                  <Th
                    width={'20px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    {t('Mahsulot')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Narxi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('total_sum')}
                  </Th>
                  <Th
                    width={'20px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    {t('Del')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        width={'30px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {index + 1}
                      </Td>
                      <Td
                        width={'300px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Button
                          bg={colorMode === 'light' ? 'gray.200' : 'blue.900'}
                          textColor={colorMode === 'light' ? 'blue' : 'white'}
                          width={'full'}
                          border="1px"
                          borderColor="gray.200"
                          value={get(v, 'itemName', '')}
                          onClick={() => {
                            setIdx(index);
                            setIsItemsByWarehouseModalOpen(true);
                          }}
                        >
                          {v.itemName} {v.itemCode}
                        </Button>
                      </Td>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        onClick={() => {
                          productsInWhsRef.current?.open(
                            get(v, 'itemCode', ''),
                          );
                        }}
                      >
                        {get(v, 'itemName', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'quantity', '')}
                          onChange={(title) =>
                            changeQuant(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'unitPrice', '')}
                          onChange={(title) =>
                            changePrice(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'unitPrice', '') * get(v, 'quantity', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        width={'20px'}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        textAlign={'center'}
                      >
                        <IconButton
                          onClick={() => remove(index)}
                          backgroundColor={'red.100'}
                          _hover={{ bg: 'red.200' }}
                          borderRadius={'8px'}
                          icon={
                            <DeleteIcon color={'red.600'} w="16px" h="16px" />
                          }
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

          <Box justifyContent={'space-between'} display={'flex'} mt={'20px'}>
            <IconButton
              onClick={addItem}
              _hover={false}
              backgroundColor={'brand.200'}
              icon={<AddIcon color={'white'} w="10px" h="10px" />}
            />
            <Flex gap={3}>
              <Input
                placeholder={`${t('docTotal')}`}
                value={
                  docTotal
                    ? `${t('docTotal')} ${new Intl.NumberFormat().format(
                        docTotal,
                      )}  ${valuta}`
                    : ''
                }
                width={300}
                borderRadius={'15px'}
                color={btnTextColor}
                disabled={true}
              />
              <Button
                isLoading={isLoading}
                onClick={createNewRequest}
                width={200}
                backgroundColor="green.500"
              >
                {t('Yaratish')}
              </Button>
            </Flex>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />

      <ProductsInWarehouseModal
        getRef={(r) => (productsInWhsRef.current = r)}
      />
      <ClientsModal
        getRef={(r) => (clientsRef.current = r)}
        onConfirm={(v) => {
          setUser(v);
          console.log(v);
        }}
      />
      <ItemsByWarehouseByPriceListModal
        isOpen={isItemsByWarehouseModalOpen}
        onClose={setIsItemsByWarehouseModalOpen}
        user={user}
        wData={{
          warehouseCode: uWarehouse,
          warehouseName: `${t('Ombor tanlang')} : ${uWarehouse}`,
        }}
        handleSelect={handleSelect}
        idx={idx}
      />
    </Style>
  );
}
