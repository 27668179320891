import React, { Fragment } from 'react';
import { Box, Text, Spinner, Flex, Divider } from '@chakra-ui/react';
import api from 'api';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

export default function UserReports() {
  const { t } = useTranslation();

  const { token } = useSelector((state) => state.main);

  const fetchTodaysInvoices = async () => {
    const { data } = await api.get('invoices/today-statistics/pagination/0', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(JSON.parse(data).data);

    return JSON.parse(data).data;
  };

  const {
    data: todayInvoices,
    isFetching: isFetchingTodayInvoice,
    isError: isErrorTodayInvoice,
  } = useQuery(['todaysInvoices'], fetchTodaysInvoices, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const fetchTodaysIncomings = async () => {
    const { data } = await api.get(
      'incoming-payments/today-statistics/pagination/0',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return JSON.parse(data).data;
  };

  const {
    data: todayIncomings,
    isFetching: isFetchingTodayIncomings,
    isError: isErrorTodayIncomings,
  } = useQuery(['todaysIncomingss'], fetchTodaysIncomings, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const fetchTodaysOutgoings = async () => {
    const { data } = await api.get(
      'outgoing-payments/today-statistics/pagination/0',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log(JSON.parse(data).data);

    return JSON.parse(data).data;
  };

  const {
    data: todayOutgoings,
    isFetching: isFetchingTodayOutgoings,
    isError: isErrorTodayOutgoings,
  } = useQuery(['todaysOutgoings'], fetchTodaysOutgoings, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  if (isErrorTodayInvoice || isErrorTodayIncomings || isFetchingTodayInvoice) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
          {t('Malumotlarni yuklab olishni iloji bolmadi')}
        </Text>
      </Box>
    );
  }
  if (
    isFetchingTodayOutgoings ||
    isFetchingTodayIncomings ||
    isErrorTodayOutgoings
  ) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex alignItems="center" justifyContent="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      </Box>
    );
  }
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" gap="20px" mb="20px">
        <Flex
          direction="column"
          gap="20px"
          rounded={'2xl'}
          shadow={'md'}
          p="4"
          flex={1}
          w={'96'}
        >
          <Text fontSize={'xl'} fontWeight={'bold'}>
            {t('Today invoices')}
          </Text>
          <Box display="flex" flexDirection="column" gap="4">
            <Text fontSize={'xl'} fontWeight={'bold'}>
              {`${new Intl.NumberFormat('fr-FR').format(
                todayInvoices[0]?.dollarAmount,
              )} USD`}
            </Text>
            <Text fontSize={'xl'} fontWeight={'bold'}>
              {`${new Intl.NumberFormat('fr-FR').format(
                todayInvoices[0]?.sumAmount,
              )} UZS`}
            </Text>
            <Text fontSize={'xl'} fontWeight={'bold'}>
              {`${new Intl.NumberFormat('fr-FR').format(
                todayInvoices[0]?.totalWeight,
              )} ${t('tonn')}`}
            </Text>
          </Box>
        </Flex>
        <Flex
          direction="column"
          gap="20px"
          flex={1}
          w={'full'}
          rounded={'2xl'}
          shadow={'md'}
          p="4"
        >
          <Text fontSize={'xl'} fontWeight={'bold'}>
            {t('Today incoming payments')}
          </Text>
          {todayIncomings.length ? (
            <>
              <Box
                as="div"
                display="grid"
                gridTemplateColumns="repeat(3, 1fr)"
                gap="16px"
              >
                {todayIncomings.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <Box display="flex" flexDirection="column" gap="4">
                        <Text fontSize={'xl'} fontWeight={'bold'}>
                          {`${item.acctName}`}
                        </Text>
                        <Text fontSize={'xl'} fontWeight={'bold'}>
                          {new Intl.NumberFormat('fr-FR').format(
                            item.dollarAmount,
                          )}{' '}
                          <Text as="span" color="gray.500">
                            {' '}
                            USD
                          </Text>
                        </Text>
                        <Text fontSize={'xl'} fontWeight={'bold'}>
                          {new Intl.NumberFormat('fr-FR').format(
                            item.sumAmount,
                          )}{' '}
                          <Text as="span" color="gray.500">
                            {' '}
                            UZS
                          </Text>
                        </Text>
                      </Box>
                    </Fragment>
                  );
                })}
              </Box>
              <Divider />
              <Box>
                <Text>{t('total')}</Text>
                <Text>{`${new Intl.NumberFormat('fr-FR').format(
                  todayIncomings.reduce(
                    (a, b) => a + parseFloat(b.dollarAmount),
                    0,
                  ),
                )} USD`}</Text>
                <Text>{`${new Intl.NumberFormat('fr-FR').format(
                  todayIncomings.reduce(
                    (a, b) => a + parseFloat(b.sumAmount),
                    0,
                  ),
                )} UZS`}</Text>
              </Box>
            </>
          ) : (
            <Text fontSize={'lg'} fontWeight={'normal'} color="gray.500">
              {t('no_data')}
            </Text>
          )}
        </Flex>
        <Flex
          direction="column"
          gap="20px"
          flex={1}
          w={'full'}
          rounded={'2xl'}
          shadow={'md'}
          p="4"
        >
          <Text fontSize={'xl'} fontWeight={'bold'}>
            {t('Today outgoing payments')}
          </Text>
          <Divider />
          {todayOutgoings.length ? (
            <>
              <Box
                as="div"
                display="grid"
                gridTemplateColumns="repeat(3, 1fr)"
                gap="16px"
              >
                {todayOutgoings.map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="column"
                    gap="4"
                  >
                    <Text
                      fontSize="xl"
                      fontWeight="bold"
                    >{`${item.acctName}`}</Text>
                    <Text fontSize="xl" fontWeight="bold">
                      {new Intl.NumberFormat('fr-FR').format(item.dollarAmount)}{' '}
                      <Text as="span" color="gray.500">
                        {' '}
                        USD
                      </Text>
                    </Text>
                    <Text fontSize="xl" fontWeight="bold">
                      {new Intl.NumberFormat('fr-FR').format(item.sumAmount)}{' '}
                      <Text as="span" color="gray.500">
                        {' '}
                        UZS
                      </Text>
                    </Text>
                    <Divider />
                  </Box>
                ))}
              </Box>
              <Box>
                <Text>{t('total')}</Text>
                <Text>
                  {`${new Intl.NumberFormat('fr-FR').format(
                    todayOutgoings.reduce(
                      (a, b) => a + parseFloat(b.dollarAmount),
                      0,
                    ),
                  )} USD`}
                </Text>
                <Text>
                  {`${new Intl.NumberFormat('fr-FR').format(
                    todayOutgoings.reduce(
                      (a, b) => a + parseFloat(b.sumAmount),
                      0,
                    ),
                  )} UZS`}
                </Text>
              </Box>
            </>
          ) : (
            <Text fontSize="lg" fontWeight="normal" color="gray.500">
              {t('no_data')}
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
