import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import ezoz_logo from '../../../assets/img/dashboards/logo.png';

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      <div className="flex h-28 items-center justify-center">
        <Image src={ezoz_logo} h="full" w="44" alt="E'zoz logo" />
      </div>
    </Flex>
  );
}

export default SidebarBrand;
