import React, { useEffect, useState } from 'react';
import { Box, Icon, Text, SimpleGrid, Divider } from '@chakra-ui/react';
import MiniStatistics from 'components/card/MiniStatistics';
import { HiCurrencyDollar } from 'react-icons/hi2';
import { FaMoneyBill, FaWarehouse } from 'react-icons/fa6';
import { FaQrcode } from 'react-icons/fa';
import IconBox from 'components/icons/IconBox';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';

export default function KassaSHOP() {
  const { t } = useTranslation();

  const { token, getMe } = useSelector((state) => state.main);
  const uAccount = get(getMe, 'uAccount', '');
  const uDollarAccount = get(getMe, 'uDollarAccount', '');

  const fetchOrders = async () => {
    const response = await api.get(
      `chart-of-accounts/dollar-account/${uDollarAccount}/sum-account/${uAccount}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    'kassaSHOP',
    () => fetchOrders(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  if (isError) {
    return (
      <Style>
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
            <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
              {t('Malumotlarni yuklab olishni iloji bolmadi')}
            </Text>
          </Card>
        </Box>
      </Style>
    );
  }

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        {mainData.length ? (
          <Box
            as="div"
            display="grid"
            gridTemplateColumns="repeat(4, 1fr)"
            gap="16px"
          >
            {mainData.map((el, index) => {
              return (
                <Box
                  key={index}
                  as="div"
                  display="flex"
                  flexDirection="column"
                  gap="4"
                  rounded={'2xl'}
                  shadow={'md'}
                  p="4"
                >
                  <Text fontSize={'xl'} fontWeight={'bold'}>
                    {el.name}
                  </Text>
                  <Divider />
                  <Box display="flex" flexDirection="column" gap="4">
                    <Text fontSize={'xl'} fontWeight={'bold'}>
                      {el.balance}{' '}
                      <Text as="span" color="gray.500">
                        {el.acctCurrency !== '##'
                          ? el.acctCurrency
                          : `USD ${t('and')} UZS`}
                      </Text>
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Text fontSize={'lg'} fontWeight={'normal'} color="gray.500">
            {t('no_data')}
          </Text>
        )}
      </Box>
    </Style>
  );
}
