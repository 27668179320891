import React from 'react';
//icons
import { Icon } from '@chakra-ui/react';
import { AiFillHome } from 'react-icons/ai';
import { RiShoppingCart2Fill } from 'react-icons/ri';
import { FaTruck } from 'react-icons/fa';
import { FaMoneyCheckDollar } from 'react-icons/fa6';
import { HiCurrencyDollar } from 'react-icons/hi2';
import { PiCashRegisterFill } from 'react-icons/pi';
import { FaUsers } from 'react-icons/fa';
import { MdSell } from 'react-icons/md';

//screens
import MainDashboard from 'views/admin/default';
import RequestShop from 'views/admin/requestShop';
import SalesOrderSHOP from 'views/admin/salesOrderSHOP';
import SalesTelegramSHOP from 'views/admin/salesTelegramSHOP';
import SalesSHOP from 'views/admin/salesSHOP';
import CanceledSalesSHOP from 'views/admin/canceledSalesSHOP';
import SalesReportsSHOP from 'views/admin/salesReportsSHOP';
import PurchaseSHOP from 'views/admin/purchaseSHOP';
import CanceledPurchaseSHOP from 'views/admin/canceledPurchaseSHOP';
import PurchaseReportsSHOP from 'views/admin/purchaseReportsSHOP';
import DollarCourse from 'views/admin/dollarCourse';
import KassaSHOP from 'views/admin/kassaSHOP';
import ListClientsSHOP from 'views/admin/listClientsSHOP';
import IncomingPaymentSHOP from 'views/admin/incomingPaymentSHOP';
import OutgoingPaymentSHOP from 'views/admin/outgoingPaymentSHOP';
import TelegramPaymentsSHOP from 'views/admin/telegramPaymentsSHOP';
import Stock from 'views/admin/stock';
import OutgoingInProgress from './views/admin/outgoingInProgress';
import ShopRequest from './views/admin/shopRequest/index';
import IncomingPending from './views/admin/incomingPending/index';
import CompletedRequest from './views/admin/completedRequest/index';
import PaymentHistorySHOP from './views/admin/paymentHistorySHOP/index';
import ExchangeHistory from './views/admin/exchangeHistorySHOP/index';
import Exchange from './views/admin/exchange/index';

const routes = [
  {
    mainName: 'Основной',
    single: true,
    layout: '/admin',
    path: '/default',
    icon: <Icon as={AiFillHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    mainName: 'Продажа',
    single: false,
    icon: (
      <Icon
        as={RiShoppingCart2Fill}
        width="19px"
        height="19px"
        color="inherit"
      />
    ),
    miniMenu: [
      {
        name: 'Заказ на продажу',
        layout: '/admin',
        path: '/salesordershop',
        icon: (
          <Icon
            as={RiShoppingCart2Fill}
            width="19px"
            height="19px"
            color="inherit"
          />
        ),
        component: <SalesOrderSHOP />,
      },
      // {
      //   name: 'Телеграм заказ',
      //   layout: '/admin',
      //   path: '/salestelegramshop',
      //   icon: (
      //     <Icon
      //       as={RiShoppingCart2Fill}
      //       width="19px"
      //       height="19px"
      //       color="inherit"
      //     />
      //   ),
      //   component: <SalesTelegramSHOP />,
      // },
      {
        name: 'Продажа',
        layout: '/admin',
        path: '/salesshop',
        icon: (
          <Icon
            as={RiShoppingCart2Fill}
            width="19px"
            height="19px"
            color="inherit"
          />
        ),
        component: <SalesSHOP />,
      },
      {
        name: 'Отмененные продажи',
        layout: '/admin',
        path: '/canceledsalesshop',
        icon: (
          <Icon
            as={RiShoppingCart2Fill}
            width="19px"
            height="19px"
            color="inherit"
          />
        ),
        component: <CanceledSalesSHOP />,
      },
      {
        name: 'Отчеты по продажам',
        layout: '/admin',
        path: '/salesreportsshop',
        icon: (
          <Icon
            as={RiShoppingCart2Fill}
            width="19px"
            height="19px"
            color="inherit"
          />
        ),
        component: <SalesReportsSHOP />,
      },
    ],
  },
  {
    mainName: 'Закупка',
    single: false,
    icon: <Icon as={MdSell} width="18px" height="18px" color="inherit" />,
    miniMenu: [
      {
        name: 'Закупка',
        layout: '/admin',
        path: '/purchaseshop',
        icon: <Icon as={MdSell} width="18px" height="18px" color="inherit" />,
        component: <PurchaseSHOP />,
      },
      {
        name: 'Отмененные закупки',
        layout: '/admin',
        path: '/canceledpurchaseshop',
        icon: <Icon as={MdSell} width="18px" height="18px" color="inherit" />,
        component: <CanceledPurchaseSHOP />,
      },
      {
        name: 'Отчеты по закупками',
        layout: '/admin',
        path: '/purchasereportsshop',
        icon: <Icon as={MdSell} width="18px" height="18px" color="inherit" />,
        component: <PurchaseReportsSHOP />,
      },
    ],
  },
  {
    mainName: 'Оплата',
    single: false,
    icon: (
      <Icon
        as={FaMoneyCheckDollar}
        width="18px"
        height="18px"
        color="inherit"
      />
    ),
    miniMenu: [
      {
        name: 'Входящий платеж',
        layout: '/admin',
        path: '/incomingpaymentshop',
        icon: (
          <Icon
            as={FaMoneyCheckDollar}
            width="18px"
            height="18px"
            color="inherit"
          />
        ),
        component: <IncomingPaymentSHOP />,
      },
      {
        name: 'Исходящий платеж',
        layout: '/admin',
        path: '/outgoingpaymentshop',
        icon: (
          <Icon
            as={FaMoneyCheckDollar}
            width="18px"
            height="18px"
            color="inherit"
          />
        ),
        component: <OutgoingPaymentSHOP />,
      },
      // {
      //   name: 'Телеграм платеж',
      //   layout: '/admin',
      //   path: '/telegramtaymentsshop',
      //   icon: (
      //     <Icon
      //       as={FaMoneyCheckDollar}
      //       width="18px"
      //       height="18px"
      //       color="inherit"
      //     />
      //   ),
      //   component: <TelegramPaymentsSHOP />,
      // },
      {
        name: 'История платежей',
        layout: '/admin',
        path: '/paymenthistoryshop',
        icon: (
          <Icon
            as={FaMoneyCheckDollar}
            width="18px"
            height="18px"
            color="inherit"
          />
        ),
        component: <PaymentHistorySHOP />,
      },
    ],
  },
  {
    mainName: 'Склад',
    single: true,
    layout: '/admin',
    path: '/stock',
    icon: <Icon as={FaUsers} width="20px" height="20px" color="inherit" />,
    component: <Stock />,
  },
  {
    mainName: 'Kurs',
    single: true,
    layout: '/admin',
    path: '/dollarcourse',
    icon: (
      <Icon as={HiCurrencyDollar} width="20px" height="20px" color="inherit" />
    ),
    component: <DollarCourse />,
  },

  {
    mainName: 'Kassa',
    single: false,
    icon: (
      <Icon
        as={FaMoneyCheckDollar}
        width="18px"
        height="18px"
        color="inherit"
      />
    ),
    miniMenu: [
      {
        name: 'Остаток кассы',
        layout: '/admin',
        path: '/kassashop',
        icon: (
          <Icon
            as={FaMoneyCheckDollar}
            width="18px"
            height="18px"
            color="inherit"
          />
        ),
        component: <KassaSHOP />,
      },
      {
        name: 'Конвертация',
        layout: '/admin',
        path: '/exchange',
        icon: (
          <Icon
            as={FaMoneyCheckDollar}
            width="18px"
            height="18px"
            color="inherit"
          />
        ),
        component: <Exchange />,
      },
      {
        name: 'История конвертации',
        layout: '/admin',
        path: '/exchangehistoryshop',
        icon: (
          <Icon
            as={FaMoneyCheckDollar}
            width="18px"
            height="18px"
            color="inherit"
          />
        ),
        component: <ExchangeHistory />,
      },
    ],
  },
  {
    mainName: 'Список лиентов',
    single: true,
    layout: '/admin',
    path: '/listclientsshop',
    icon: <Icon as={FaUsers} width="20px" height="20px" color="inherit" />,
    component: <ListClientsSHOP />,
  },
  // {
  //   mainName: 'Перемешение запосов',
  //   single: false,
  //   icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //   miniMenu: [
  //     {
  //       name: 'Создать запрос',
  //       layout: '/admin',
  //       path: '/requestshop',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <RequestShop />,
  //     },
  //     {
  //       name: 'Исходящие в ожидании',
  //       layout: '/admin',
  //       path: '/outgoinginprogress',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <OutgoingInProgress />,
  //     },
  //     {
  //       name: 'Запросы от склада',
  //       layout: '/admin',
  //       path: '/shoprequest',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <ShopRequest />,
  //     },
  //     {
  //       name: 'Входящие в ожидании',
  //       layout: '/admin',
  //       path: '/incomingpending',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <IncomingPending />,
  //     },
  //     {
  //       name: 'Заверщенные',
  //       layout: '/admin',
  //       path: '/completedrequest',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <CompletedRequest />,
  //     },
  //   ],
  // },
];

export default routes;
