import React, { useEffect, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  Table,
  Tbody,
  Thead,
  Text,
  Box,
  Td,
  Th,
  Tr,
  SimpleGrid,
  Input as MainInput,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import time from 'helpers/time';
import { get } from 'lodash';
import api from 'api';
import { FaEye } from 'react-icons/fa';
import Pagination from 'components/Pagination';
import ViewRelocationSHOPModal from 'components/Modals/ViewRelocationSHOPModal';

export default function ViewRelocationSHOP() {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const { token } = useSelector((state) => state.main);

  const location = useLocation();
  const sendedData = get(location, 'state', {});
  const cardCode = get(sendedData, 'cardCode', '');
  const [document, setDocument] = useState({});

  const [isPending, setIsPending] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');

  const fetchOrders = async ({ queryKey }) => {
    const [url, startDate, endDate, pagination] = queryKey;
    const params = new URLSearchParams();
    if (startDate && endDate) {
      if (startDate) params.append('startDate', startDate);
      if (endDate) params.append('endDate', endDate);
    }
    params.append('skip', pagination);
    const queryString = params.toString();

    const response = await api.get(
      `journal-entries/card-code/${cardCode}?${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['viewRelocationSHOP', startDate, endDate, pagination],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    } else {
      setMainData([]);
    }
  }, [data]);

  const handleSeeDocument = async (operationTye, docEntry) => {
    if (!docEntry) {
      alert('Dokument mavjud emas');
      return;
    }

    let endpoint;
    switch (operationTye) {
      case 'Invoice':
        endpoint = 'invoices';
        break;
      case 'IncomingPayment':
        endpoint = 'incoming-payments/doc-entry';
        break;
      case 'CreditMemo':
        endpoint = 'credit-notes';
        break;
      default:
        endpoint = '';
        break;
    }
    if (!endpoint) {
      alert('Dokument mavjud emas');
      return;
    } else {
      setIsPending(true);
      const request = await api.get(`${endpoint}/${docEntry}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await JSON.parse(request.data);
      setDocument(response.data);
      setIsPending(false);
      setOpenModal(true);
    }
  };

  if (isError) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />
        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <MainInput
              isIconHas={false}
              value={`${t('Mijoz')} : ${get(sendedData, 'cardName', '')}`}
              borderRadius={'15px'}
              color={tablBody}
              disabled={true}
            />
            <MainInput
              value={`${t('Joriy balansi')} : ${get(
                sendedData,
                'currentAccountBalance',
                '',
              )}`}
              borderRadius={'15px'}
              color={tablBody}
              disabled={true}
            />
          </SimpleGrid>
          <Box mb={'20px'} display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'}>
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Boshlanish sanasi')}
                </Text>
                <Input
                  type={'date'}
                  isIconHas={false}
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={startDate}
                  onChange={(v) => setStartDate(v.target.value)}
                />
              </div>
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Tugash sanasi')}
                </Text>
                <Input
                  type={'date'}
                  isIconHas={false}
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={endDate}
                  onChange={(v) => setEndDate(v.target.value)}
                />
              </div>
            </Box>
          </Box>
          <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
            {t('Malumotlarni yuklab olishni iloji bolmadi')}
          </Text>
          <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
            {t('Boshlanish va tugash sanalarini tanlang')}
          </Text>
        </Card>
      </Box>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <IconButton
        onClick={() => navigation(-1)}
        backgroundColor={'brand.200'}
        icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
        mb={'5px'}
      />
      <Card py="20px" border="1px" borderColor="gray.200">
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
          gap="20px"
          mb="20px"
        >
          <MainInput
            isIconHas={false}
            value={`${t('Mijoz')} : ${get(sendedData, 'cardName', '')}`}
            borderRadius={'15px'}
            color={tablBody}
            disabled={true}
          />
          <MainInput
            value={`${t('balance')} : ${get(
              sendedData,
              'currentAccountBalance',
              '',
            )}`}
            borderRadius={'15px'}
            color={tablBody}
            disabled={true}
          />
        </SimpleGrid>
        <Box
          mb={'20px'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems="end"
        >
          <Box display={'flex'}>
            <div>
              <Text fontSize={14} ms={'5px'}>
                {t('Boshlanish sanasi')}
              </Text>
              <Input
                type={'date'}
                isIconHas={false}
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={startDate}
                onChange={(v) => setStartDate(v.target.value)}
              />
            </div>
            <div>
              <Text fontSize={14} ms={'5px'}>
                {t('Tugash sanasi')}
              </Text>
              <Input
                type={'date'}
                isIconHas={false}
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={endDate}
                onChange={(v) => setEndDate(v.target.value)}
              />
            </div>
          </Box>
          <Box as="div">
            <Pagination
              pagination={pagination}
              setPagination={setPagination}
              dataLength={mainData.length}
              skipPagination={false}
            />
          </Box>
        </Box>

        <Box overflow="auto">
          <Table variant={'unstyled'} color="gray.500" position={'relative'}>
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  textAlign={'center'}
                >
                  {t('docNum')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Sana')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('operationName')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('docTotal')}
                </Th>

                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('cumulativeBalance')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('more')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {mainData.map((value, index) => {
                return (
                  <Tr
                    key={index}
                    className="tr"
                    _hover={{
                      bg: bgTr,
                    }}
                  >
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                      textAlign={'center'}
                    >
                      {get(value, 'docNum', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {time.to(get(value, 'docDate', ''), 'DD-MM-YYYY')}
                    </Td>

                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {t(`${get(value, 'documentType', '')}`)}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(value, 'docTotal', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(value, 'cumulativeBalance', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      <Button
                        bg="blue.500"
                        color="white"
                        _hover={{ bg: 'blue' }}
                        rounded="xl"
                        size="sm"
                        isLoading={isPending}
                        onClick={() =>
                          handleSeeDocument(
                            get(value, 'documentType', ''),
                            get(value, 'docEntry', ''),
                          )
                        }
                      >
                        <FaEye />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <ViewRelocationSHOPModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          document={document}
        />
      </Card>
    </Box>
  );
}
