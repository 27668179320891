import { Box } from '@chakra-ui/react';
import { Button } from 'antd';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';

function Pagination({ pagination, setPagination, dataLength, skipPagination }) {
  return (
    <Box
      as="div"
      justifyContent={'end'}
      flex="1"
      mr="2"
      display="flex"
      gap={1}
      alignItems={'center'}
    >
      <Button
        variant={'outline'}
        disabled={pagination === 0}
        onClick={() =>
          setPagination((prev) => {
            if (skipPagination) return prev - 15;
            else return prev - 1;
          })
        }
      >
        <BiLeftArrow />
      </Button>
      <Box as="span">
        {skipPagination ? pagination / 15 + 1 : pagination + 1}
      </Box>
      <Button
        variant={'outline'}
        disabled={dataLength < 20}
        onClick={() =>
          setPagination((prev) => {
            if (skipPagination) return prev + 15;
            else return prev + 1;
          })
        }
      >
        <BiRightArrow />
      </Button>
    </Box>
  );
}

export default Pagination;
