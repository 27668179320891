import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FaTruck } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { IoDocumentText } from 'react-icons/io5';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import { RiShoppingCart2Fill } from 'react-icons/ri';

import Stock from './views/admin/stock';
import Request from './views/admin/request';
import MainDashboard from 'views/admin/default';
import Shipments from './views/admin/shipments';
import Purchases from './views/admin/purchases';
import ZeroBalance from './views/admin/zeroBalance';
import ShopRequest from './views/admin/shopRequest';
import IncomingPending from './views/admin/incomingPending';
import CompletionOfSale from './views/admin/completionOfSale';
import CompletedRequest from './views/admin/completedRequest';
import OrdersFromSalesDepartment from './views/admin/ordersFromSalesDepartment';
import OutgoingInProgress from './views/admin/outgoingInProgress';
const routes = [
  {
    mainName: 'Основной',
    single: true,
    layout: '/admin',
    path: '/default',
    icon: <Icon as={AiFillHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    mainName: 'Закупки',
    single: true,
    layout: '/admin',
    path: '/purchases',
    icon: (
      <Icon
        as={BiSolidPurchaseTag}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <Purchases />,
  },
  {
    mainName: 'Заказ на продажу',
    single: false,
    icon: (
      <Icon
        as={RiShoppingCart2Fill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    miniMenu: [
      {
        name: 'Заказы от отдела продаж',
        layout: '/admin',
        path: '/ordersfromsalesdepartment',
        icon: (
          <Icon
            as={RiShoppingCart2Fill}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: <OrdersFromSalesDepartment />,
      },
      {
        name: 'Отгрузки',
        layout: '/admin',
        path: '/shipments',
        icon: (
          <Icon
            as={RiShoppingCart2Fill}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: <Shipments />,
      },
      {
        name: 'Завершение продажи',
        layout: '/admin',
        path: '/completionofsale',
        icon: (
          <Icon
            as={RiShoppingCart2Fill}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: <CompletionOfSale />,
      },
    ],
  },
  // {
  //   mainName: 'Перемешение запосов',
  //   single: false,
  //   icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //   miniMenu: [
  //     {
  //       name: 'Мои запросы',
  //       layout: '/admin',
  //       path: '/request',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <Request />,
  //     },
  //     {
  //       name: 'Исходящие в ожидании',
  //       layout: '/admin',
  //       path: '/outgoinginprogress',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <OutgoingInProgress />,
  //     },
  //     {
  //       name: 'Запросы от магазина',
  //       layout: '/admin',
  //       path: '/shoprequest',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <ShopRequest />,
  //     },
  //     {
  //       name: 'Входящие в ожидании',
  //       layout: '/admin',
  //       path: '/incomingpending',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <IncomingPending />,
  //     },
  //     {
  //       name: 'Заверщенные',
  //       layout: '/admin',
  //       path: '/completedrequest',
  //       icon: <Icon as={FaTruck} width="19px" height="19px" color="inherit" />,
  //       component: <CompletedRequest />,
  //     },
  //   ],
  // },
  {
    mainName: 'Отчеты',
    single: false,
    icon: (
      <Icon as={IoDocumentText} width="20px" height="20px" color="inherit" />
    ),
    miniMenu: [
      {
        name: 'Остатки',
        layout: '/admin',
        path: '/stock',
        icon: (
          <Icon
            as={IoDocumentText}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: <Stock />,
      },
      {
        name: 'Нулевые остатки',
        layout: '/admin',
        path: '/zerobalance',
        icon: (
          <Icon
            as={IoDocumentText}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: <ZeroBalance />,
      },
    ],
  },
];

export default routes;
