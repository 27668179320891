import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
function ViewRelocationSHOPModal({ openModal, setOpenModal, document }) {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const location = useLocation();
  const sendedData = get(location, 'state', {});

  const topInfo = [
    { label: t('name'), value: document.cardName || 'Null' },
    {
      label: t('currentBalance'),
      value: get(sendedData, 'currentAccountBalance', ''),
    },
    {
      label: t('docDate'),
      value: document.docDate ? document.docDate.split('T')[0] : 'Null',
    },
    {
      label: t('docCurrency'),
      value: document.docCurrency || 'Null',
    },
  ];

  const onClose = () => setOpenModal(false);

  return (
    <Modal
      onClose={onClose}
      isOpen={openModal}
      size="6xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('about_document', { doc_num: document.docEntry })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer mb={6}>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  {document &&
                    topInfo.map((info, index) => (
                      <Td key={index}>
                        <Flex align="center" gap={2}>
                          <Box>
                            <Text fontSize="sm" color="gray.500">
                              {info.label}
                            </Text>
                            <Text>{info.value}</Text>
                          </Box>
                        </Flex>
                      </Td>
                    ))}
                </Tr>
                <Tr>
                  <Td maxW="200px" wordBreak="break-word">
                    {t('comment')}: {document.comments || t('no_comment')}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          {/* Document Lines Table */}
          {document.documentLines && (
            <TableContainer
              bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}
              borderRadius="lg"
              p={4}
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>{t('itemDescription')}</Th>
                    <Th>{t('quantity')}</Th>
                    <Th>{t('unitPrice')}</Th>
                    <Th>{t('lineTotal')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {document.documentLines.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.itemDescription}</Td>
                      <Td>{item.quantity}</Td>
                      <Td>{item.unitPrice}</Td>
                      <Td>{item.quantity * item.unitPrice}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <Box
            as="div"
            display="flex"
            flexDir="row"
            justifyContent="end"
            gap="2"
            mt="2"
            ml="2"
            mr="10"
          >
            <Box as="div">
              {t('docTotal')}: {document.docTotal || '0'}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalFooter>
            <Button
              loadingText={t('go_back')}
              variant="outline"
              bg="red.500"
              ml="2"
              color="white"
              _hover={{ bg: 'red.700' }}
              onClick={onClose}
            >
              {t('go_back')}
            </Button>
          </ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ViewRelocationSHOPModal;
