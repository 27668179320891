import React, { useState } from 'react';
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import { SidebarContext } from 'contexts/SidebarContext';
import Footer from 'components/footer/FooterAdmin.js';
import Navbar from 'components/navbar/NavbarAdmin.js';
import MainDashboard from '../../views/admin/default';
import SignInCentered from '../../views/auth/signIn';
import Sidebar from 'components/sidebar/Sidebar.js';
import { Route, Routes } from 'react-router-dom';
//address_route
import routesSklad from 'routes';
import routesShop from 'routesShop';
import { useSelector } from 'react-redux';
//screens
import OrdersFromSalesDepartment from '../../views/admin/ordersFromSalesDepartment';
import ZeroBalanceMiniGroup from '../../views/admin/zeroBalanceMiniGroup';
import ViewcompletionOfSale from '../../views/admin/viewcompletionOfSale';
import ViewSalesDepartment from '../../views/admin/viewsalesdepartment';
import CompletionOfSale from '../../views/admin/completionOfSale';
import StockMiniGroup from '../../views/admin/stockMiniGroup';
import ViewShipments from '../../views/admin/viewshipments';
import ViewPurchases from '../../views/admin/viewPurchases';
import ZeroBalance from '../../views/admin/zeroBalance';
import Purchases from '../../views/admin/purchases';
import Shipments from '../../views/admin/shipments';
import Request from '../../views/admin/request';
import Stock from '../../views/admin/stock';
import ViewRequest from '../../views/admin/viewRequest';
import CreateRequest from '../../views/admin/createRequest';
import ShopRequest from '../../views/admin/shopRequest';
import ViewShopRequest from '../../views/admin/viewShopRequest';
import CreateShopRequest from '../../views/admin/createShopRequest';
import IncomingPending from '../../views/admin/incomingPending';
import ViewIncomingPending from '../../views/admin/viewIncomingPending';
import CompletedRequest from '../../views/admin/completedRequest';
import ViewCompleted from '../../views/admin/viewCompleted';
import RequestShop from '../../views/admin/requestShop';
import ViewRequestShop from 'views/admin/viewRequestShop';
import CreateRequestShop from 'views/admin/createRequestShop';
import SalesOrderSHOP from 'views/admin/salesOrderSHOP';
import ViewSalesOrderSHOP from 'views/admin/viewSalesOrderSHOP';
import CreateSalesSHOP from 'views/admin/createSalesSHOP';
import SalesTelegramSHOP from 'views/admin/salesTelegramSHOP';
import ViewsalesTelegramSHOP from 'views/admin/viewsalesTelegramSHOP';
import SalesSHOP from 'views/admin/salesSHOP';
import CreateSSHOP from 'views/admin/createSSHOP';
import ViewSSHOP from 'views/admin/viewSSHOP';
import CanceledSalesSHOP from 'views/admin/canceledSalesSHOP';
import ViewCanceledSalesSHOP from 'views/admin/viewCanceledSalesSHOP';
import SalesReportsSHOP from 'views/admin/salesReportsSHOP';
import ViewRelocationSHOP from 'views/admin/viewRelocationSHOP';
import ViewTotalSalesSHOP from 'views/admin/viewTotalSalesSHOP';
import PurchaseSHOP from 'views/admin/purchaseSHOP';
import ViewPurchaseSHOP from 'views/admin/viewPurchaseSHOP';
import CreatePurchaseSHOP from 'views/admin/createPurchaseSHOP';
import CanceledPurchaseSHOP from 'views/admin/canceledPurchaseSHOP';
import ViewCancelPurchaseSHOP from 'views/admin/viewCancelPurchaseSHOP';
import PurchaseReportsSHOP from 'views/admin/purchaseReportsSHOP';
import ViewAccountsPayableSHOP from 'views/admin/viewAccountsPayableSHOP';
import DollarCourse from 'views/admin/dollarCourse';
import KassaSHOP from 'views/admin/kassaSHOP';
import ListClientsSHOP from 'views/admin/listClientsSHOP';
import CreateListClientsSHOP from 'views/admin/createListClientsSHOP';
import ViewListClientsSHOP from 'views/admin/viewListClientsSHOP';
import IncomingPaymentSHOP from 'views/admin/incomingPaymentSHOP';
import ViewIncomingPaymentSHOP from 'views/admin/viewIncomingPaymentSHOP';
import CreateIncomingPaymentSHOP from 'views/admin/createIncomingPaymentSHOP';
import OutgoingPaymentSHOP from 'views/admin/outgoingPaymentSHOP';
import OutgoingInProgress from 'views/admin/outgoingInProgress';
import ViewOutgoingInProgress from 'views/admin/viewOutgoingInProgress';
import ViewPurchasereportSHOP from 'views/admin/viewPurchasereportSHOP';
import CreateOutgoingPaymentSHOP from 'views/admin/createOutgoingPaymentSHOP';
import ViewOutgoingPaymentSHOP from 'views/admin/viewOutgoingPaymentSHOP';
import PaymentHistorySHOP from 'views/admin/paymentHistorySHOP';
import ViewPaymentHistorySHOP from 'views/admin/viewPaymentHistorySHOP';
import TelegramPaymentsSHOP from 'views/admin/telegramPaymentsSHOP';
import ViewTelegramOrderSHOP from 'views/admin/viewTelegramOrderSHOP';
import CreateCanceledSalesSHOP from 'views/admin/createCanceledSalesSHOP';
import ExchangeHistory from '../../views/admin/exchangeHistorySHOP/index';
import ViewExchangeHistorySHOP from 'views/admin/viewExchangeHistorySHOP';
import Exchange from '../../views/admin/exchange/index';

export default function Dashboard(props) {
  const { jobTitle } = useSelector((state) => state.main);

  let routes = [];

  if (jobTitle === 'warehouse_manager') {
    routes = routesSklad;
  } else if (jobTitle === 'shopkeeper') {
    routes = routesShop;
  } else if (jobTitle === 'showroom') {
    routes = routesShop;
  } else {
    routes = [];
  }

  //ichkaridagi scrren lar bitta shu joyga yoziladi
  //dashboard menulari esa shsu joyga va route file ga yoziladi roli ga qarab

  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };

  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  document.documentElement.dir = 'ltr';

  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box>
                <Navbar
                  onOpen={onOpen}
                  logoText={"E'zoz"}
                  secondary={getActiveNavbar(routes)}
                  message={getActiveNavbarText(routes)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>

            {getRoute() ? (
              <Box
                mx="auto"
                p={{ base: '20px', md: '30px' }}
                pe="20px"
                minH="95vh"
                pt="50px"
              >
                <Routes>
                  <Route path="/" element={<SignInCentered />} />
                  <Route path="default" element={<MainDashboard />} />
                  <Route
                    path="ordersfromsalesdepartment"
                    element={<OrdersFromSalesDepartment />}
                  />
                  <Route path="shipments" element={<Shipments />} />
                  <Route
                    path="completionofsale"
                    element={<CompletionOfSale />}
                  />
                  <Route
                    path="ordersfromsalesdepartment/viewsalesdepartment"
                    element={<ViewSalesDepartment />}
                  />
                  <Route
                    path="shipments/viewshipments"
                    element={<ViewShipments />}
                  />
                  <Route
                    path="completionofsale/viewcompletionofsale"
                    element={<ViewcompletionOfSale />}
                  />
                  <Route path="request" element={<Request />} />
                  <Route
                    path="request/createRequest"
                    element={<CreateRequest />}
                  />
                  <Route path="request/viewRequest" element={<ViewRequest />} />
                  <Route path="purchases" element={<Purchases />} />
                  <Route
                    path="purchases/viewPurchases"
                    element={<ViewPurchases />}
                  />
                  <Route path="stock" element={<Stock />} />
                  <Route
                    path="stock/stockMiniGroup"
                    element={<StockMiniGroup />}
                  />
                  <Route path="zerobalance" element={<ZeroBalance />} />
                  <Route
                    path="zerobalance/zeroBalanceMiniGroup"
                    element={<ZeroBalanceMiniGroup />}
                  />
                  {/* <Route path="shoprequest" element={<ShopRequest />} /> */}
                  <Route
                    path="shoprequest/viewShopRequest"
                    element={<ViewShopRequest />}
                  />
                  <Route
                    path="shoprequest/createShopRequest"
                    element={<CreateShopRequest />}
                  />
                  {/* <Route path="incomingpending" element={<IncomingPending />} /> */}
                  <Route
                    path="incomingpending/viewIncomingPending"
                    element={<ViewIncomingPending />}
                  />
                  {/* <Route
                    path="completedrequest"
                    element={<CompletedRequest />}
                  /> */}
                  <Route
                    path="completedrequest/viewCompleted"
                    element={<ViewCompleted />}
                  />
                  {/* <Route path="requestshop" element={<RequestShop />} /> */}
                  <Route
                    path="requestshop/viewRequestShop"
                    element={<ViewRequestShop />}
                  />
                  <Route
                    path="requestshop/createRequestShop"
                    element={<CreateRequestShop />}
                  />
                  <Route path="salesordershop" element={<SalesOrderSHOP />} />
                  <Route
                    path="salesordershop/viewSalesOrderSHOP"
                    element={<ViewSalesOrderSHOP />}
                  />
                  <Route
                    path="salesordershop/createSalesSHOP"
                    element={<CreateSalesSHOP />}
                  />
                  {/* <Route
                    path="salestelegramshop"
                    element={<SalesTelegramSHOP />}
                  /> */}
                  <Route
                    path="salestelegramshop/viewsalesTelegramSHOP"
                    element={<ViewsalesTelegramSHOP />}
                  />
                  <Route path="salesshop" element={<SalesSHOP />} />
                  <Route
                    path="salesshop/createSSHOP"
                    element={<CreateSSHOP />}
                  />
                  <Route path="salesshop/viewSSHOP" element={<ViewSSHOP />} />
                  <Route
                    path="canceledsalesshop"
                    element={<CanceledSalesSHOP />}
                  />
                  <Route
                    path="canceledsalesshop/viewCanceledSalesSHOP"
                    element={<ViewCanceledSalesSHOP />}
                  />
                  <Route
                    path="canceledsalesshop/createCanceledSalesSHOP"
                    element={<CreateCanceledSalesSHOP />}
                  />
                  <Route
                    path="salesreportsshop"
                    element={<SalesReportsSHOP />}
                  />
                  <Route
                    path="salesreportsshop/viewRelocationSHOP"
                    element={<ViewRelocationSHOP />}
                  />
                  <Route
                    path="salesreportsshop/viewTotalSalesSHOP"
                    element={<ViewTotalSalesSHOP />}
                  />
                  <Route path="purchaseshop" element={<PurchaseSHOP />} />
                  <Route
                    path="purchaseshop/viewPurchaseSHOP"
                    element={<ViewPurchaseSHOP />}
                  />
                  <Route
                    path="purchaseshop/createPurchaseSHOP"
                    element={<CreatePurchaseSHOP />}
                  />
                  <Route
                    path="canceledpurchaseshop"
                    element={<CanceledPurchaseSHOP />}
                  />
                  <Route
                    path="canceledpurchaseshop/viewCancelPurchaseSHOP"
                    element={<ViewCancelPurchaseSHOP />}
                  />
                  <Route
                    path="purchasereportsshop"
                    element={<PurchaseReportsSHOP />}
                  />
                  <Route
                    path="purchasereportsshop/viewRelocationSHOP"
                    element={<ViewRelocationSHOP />}
                  />
                  <Route
                    path="purchasereportsshop/viewAccountsPayableSHOP"
                    element={<ViewAccountsPayableSHOP />}
                  />
                  <Route path="dollarcourse" element={<DollarCourse />} />
                  <Route path="kassashop" element={<KassaSHOP />} />
                  <Route path="listclientsshop" element={<ListClientsSHOP />} />
                  <Route
                    path="listclientsshop/createListCientsSHOP"
                    element={<CreateListClientsSHOP />}
                  />
                  <Route
                    path="listclientsshop/viewListClientsSHOP"
                    element={<ViewListClientsSHOP />}
                  />
                  <Route
                    path="incomingpaymentshop"
                    element={<IncomingPaymentSHOP />}
                  />
                  <Route
                    path="incomingpaymentshop/viewIncomingPaymentSHOP"
                    element={<ViewIncomingPaymentSHOP />}
                  />
                  <Route
                    path="incomingpaymentshop/createIncomingPaymentSHOP"
                    element={<CreateIncomingPaymentSHOP />}
                  />
                  <Route
                    path="outgoingpaymentshop"
                    element={<OutgoingPaymentSHOP />}
                  />

                  <Route
                    path="outgoingpaymentshop/createOutgoingPaymentSHOP"
                    element={<CreateOutgoingPaymentSHOP />}
                  />
                  <Route
                    path="outgoingpaymentshop/viewOutgoingPaymentSHOP"
                    element={<ViewOutgoingPaymentSHOP />}
                  />
                  <Route
                    path="paymenthistoryshop"
                    element={<PaymentHistorySHOP />}
                  />
                  <Route
                    path="exchangehistoryshop"
                    element={<ExchangeHistory />}
                  />
                  <Route path="exchange" element={<Exchange />} />
                  <Route
                    path="exchangehistoryshop/viewExchangeHistorySHOP"
                    element={<ViewExchangeHistorySHOP />}
                  />
                  <Route
                    path="paymenthistoryshop/viewPaymentHistorySHOP"
                    element={<ViewPaymentHistorySHOP />}
                  />
                  {/* <Route
                    path="outgoinginprogress"
                    element={<OutgoingInProgress />}
                  /> */}
                  <Route
                    path="outgoinginprogress/viewOutgoingInProgress"
                    element={<ViewOutgoingInProgress />}
                  />
                  <Route
                    path="purchasereportsshop/viewPurchasereportSHOP"
                    element={<ViewPurchasereportSHOP />}
                  />
                  {/* <Route
                    path="telegramtaymentsshop"
                    element={<TelegramPaymentsSHOP />}
                  /> */}
                  <Route
                    path="telegramtaymentsshop/viewTelegramOrderSHOP"
                    element={<ViewTelegramOrderSHOP />}
                  />
                </Routes>
              </Box>
            ) : null}
            <Box>
              <Footer />
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
