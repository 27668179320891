import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
} from '@chakra-ui/react';
import api from 'api';
import { get } from 'lodash';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

function ItemsByWarehouseByPriceListModal({
  isOpen,
  onClose,
  user,
  wData,
  handleSelect,
  idx,
}) {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const { token } = useSelector((state) => state.main);
  const [skip, setSkip] = useState(0);
  const [itemName, setItemName] = useState('');
  const [debouncedItemName] = useDebounce(itemName, 500);
  const dataInfo = useRef([]);

  const fetchOrders = async ({ queryKey }) => {
    const [_, titleS, priceListNum, wCode, page] = queryKey;
    let url =
      titleS.length > 0
        ? `items/by-warehouse/${wCode}/by-itemName/${titleS}/by-price-list/${priceListNum}/pagination/${page}`
        : `items/by-warehouse/${wCode}/by-price-list/${priceListNum}/pagination/${page}`;

    console.log('URL:::', url);

    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, error, isLoading } = useQuery(
    [
      'productModal',
      debouncedItemName,
      get(user, 'priceListNum', ''),
      get(wData, 'warehouseCode', ''),
      skip,
    ],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  if (error) {
    return <Text>This is an error</Text>;
  }
  if (data) {
    dataInfo.current = JSON.parse(data).data;
  } else {
    dataInfo.current = [];
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose(false)}
      closeOnOverlayClick={false}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent mx="auto" my="auto">
        <ModalHeader>{t('choose_item')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display="flex" alignItems="end" justifyContent="space-between">
            <Box>
              <Text as="label">{t('item')}</Text>
              <Input
                placeholder={t('item') + '...'}
                type="text"
                value={itemName}
                onChange={(e) => setItemName(e.currentTarget.value)}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="end"
              gap="2"
            >
              <Button
                borderRadius={'8px'}
                border={'1px'}
                borderColor="lightgray"
                onClick={() => {
                  if (skip >= 20) {
                    setSkip((prev) => prev - 20);
                  }
                }}
              >
                <ArrowLeftIcon />
              </Button>
              <Text>{skip / 20 + 1}</Text>
              <Button
                borderRadius={'8px'}
                border={'1px'}
                borderColor="lightgray"
                onClick={() => {
                  if (dataInfo.current.length) {
                    setSkip((prev) => prev + 20);
                  }
                }}
              >
                <ArrowRightIcon />
              </Button>
            </Box>
          </Box>
          <Box>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Td>{t('item')}</Td>
                  <Td>{t('item_code')}</Td>
                  <Td>{t('warehouse_name')}</Td>
                  <Td>{t('available')}</Td>
                </Tr>
              </Thead>
              <Tbody>
                {dataInfo.current.length &&
                  dataInfo.current.map((el, index) => {
                    return (
                      <Tr
                        key={index}
                        cursor="pointer"
                        _hover={{
                          bg: colorMode === 'light' ? 'gray.200' : 'gray.600',
                        }}
                        onClick={() => {
                          handleSelect(idx, el);
                          onClose(false);
                        }}
                      >
                        <Th>{el.itemName}</Th>
                        <Th>{el.itemCode}</Th>
                        <Th>{el.whsName}</Th>
                        <Th>{el.available}</Th>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isLoading}
            backgroundColor="red.500"
            color={'white'}
            mr={3}
            borderRadius={'8px'}
            _hover={{ bg: 'red.600' }}
            onClick={() => onClose(false)}
          >
            {t('go_back')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ItemsByWarehouseByPriceListModal;
